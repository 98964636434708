<template>
  <div id="login" class="d-flex align-center">
    <v-container>
          <v-card class="login-form">
            <v-card-text>
              <v-row>
                <v-col class="title" cols="12" md="6">
                  <img src="@/assets/logo2022_v.svg" alt="TOKOTON">
                </v-col>
                <v-col cols="12" md="6" class="px-8">
                  <v-spacer class="d-none d-md-flex"></v-spacer>
                  <v-form ref="form" v-model="valid" class="pa-2 text-center">
                    <v-text-field :disabled="loading" label="メールアドレス" type="email" v-model="email" :rules="emailRules" required class="mb-3" prepend-icon="mdi-email-check" autofocus></v-text-field>
                    <v-text-field :disabled="loading" label="パスワード" :type="showPassword ? 'text' : 'password'" autocomplete="current-password" id="current-password" v-model="password" :rules="passwordRules" required class="mb-3" prepend-icon="mdi-lock-check" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPassword = !showPassword" @keyup.native.enter="submit"></v-text-field>
                    <v-btn :loading="loading" :disabled="!valid" @click="submit" color="primary" rounded large>ログイン</v-btn>
                  </v-form>

                  <v-spacer></v-spacer>

                  <!-- reset password form -->
                  <div class="text-right">
                  <v-dialog v-model="lostPass.dialog" width="500">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn text v-bind="attrs" v-on="on">パスワードを忘れた</v-btn>
                    </template>
                    <v-card>
                      <v-card-title class="">パスワードの再設定</v-card-title>
                      <v-card-text>
                        <info-box class="my-5">パスワード設定リンクを記載したメールを送信します。<br>※リンクの有効期限は60分です。</info-box>
                        <v-form ref="lostPassForm" v-model="lostPass.valid">
                          <v-text-field label="メールアドレス" type="email" v-model="lostPass.email" :rules="emailRules" required class="my-2" prepend-icon="mdi-email-check"></v-text-field>
                        </v-form>
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="" text @click="lostPass.dialog = false">キャンセル</v-btn>
                        <v-btn color="primary" :disabled="!lostPass.valid"  @click="passwordResetSubmit()">パスワード再設定</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  </div>

                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-alert v-if="importantMessage" border="left" class="my-10 pa-5" colored-border type="error" elevation="2">
            <h4 class="mb-2">重要なお知らせ</h4>
            <span v-html="importantMessage"></span>
          </v-alert>
    </v-container>
  </div>
</template>

<script>
import InfoBox from '../../components/InfoBox.vue'

export default {
  components: { InfoBox },
  name: 'login',
  comments: { InfoBox },
  data: function () {
    return {
      loading: false,
      valid: false,
      email: '',
      password: '',
      emailRules: [
        v => !!v || 'メールアドレスを入力してください',
        v => /.+@.+/.test(v) || '正しいメールアドレスを入力してください'
      ],
      passwordRules: [
        v => !!v || 'パスワードの入力をしてください',
        v => v.length >= 6 || 'パスワードは6文字以上を入力してください'
      ],
      showPassword: false,
      lostPass: {
        dialog: false,
        valid: false,
        email: ''
      },
      importantMessage: null
    }
  },
  computed: {
  },
  methods: {
    submit () {
      const _self = this
      if (this.$refs.form.validate()) {
        _self.loading = true
        _self.userLogin(_self.email, _self.password)
        // 認証エラー頻発によるアクセス拒否問題を軽減
        setTimeout(function () {
          if (!_self.$store.getters.loginUserInfo.id) {
            _self.password = ''
          }
          _self.loading = false
        }, 3000)
      }
    },
    passwordResetSubmit () {
      if (this.$refs.lostPassForm.validate()) {
        this.userPasswordReset(this.lostPass.email)
        this.lostPass.dialog = false
        this.lostPass.email = ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  #login{
    height: 100%;
    background: #c6d7da;
    // background: linear-gradient(105deg, #4ab4d8 40%, #99d871 60%);
    .row{
      height: 100%;
      align-items: stretch;
      & > *{
        min-height: 400px;
        flex-direction: column;
        display: flex;
        justify-content: center;
        &:first-child{
          align-items: center;
        }
      }

    }

    .title{
      background-color: #eee;
      padding: 0;
      border-radius: 10px 0 0 10px;
      img{
        max-width: 200px;
      }

      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        border-radius: 10px 10px 0 0;
      }
    }
    .login-form{
      background-color: #ffffffdd;
      box-shadow: 0 0 40px #00000033!important;
      border: 1px solid #fff;
      border-radius: 20px!important;
      // backdrop-filter: blur(10px);
    }

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
        .container{
          max-width: 900px;
        }
    }

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
        .row{
          & > *{
              min-height: 200px;
          }
        }
        .title{
          img{
            max-width: 150px;
          }
        }
    }
  }

</style>
